import React from "react"

const Menus = () => (
    <svg
        width="46"
        height="54"
        viewBox="0 0 46 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11 3V11M37.6667 19V29.6667M37.6667 19C40.6122 19 43 15.4183 43 11C43 6.58172 40.6122 3 37.6667 3C34.7211 3 32.3333 6.58172 32.3333 11C32.3333 15.4183 34.7211 19 37.6667 19ZM11 19V29.6667M11 19C12.0506 19 13.0909 18.7931 14.0615 18.391C15.0321 17.989 15.914 17.3997 16.6569 16.6569C17.3997 15.914 17.989 15.0321 18.391 14.0615C18.7931 13.0909 19 12.0506 19 11V3M11 19C9.94942 19 8.90914 18.7931 7.93853 18.391C6.96793 17.989 6.08601 17.3997 5.34315 16.6569C4.60028 15.914 4.011 15.0321 3.60896 14.0615C3.20693 13.0909 3 12.0506 3 11V3M35 29.6667H40.3333L42.3385 45.7079C42.6897 48.5179 40.4986 51 37.6667 51C34.8347 51 32.6436 48.5179 32.9949 45.7079L35 29.6667ZM8.33333 29.6667H13.6667L15.6718 45.7079C16.0231 48.5179 13.832 51 11 51C8.16804 51 5.97692 48.5179 6.32819 45.7079L8.33333 29.6667Z"
            stroke="#FAFAFA"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default Menus
