import React from "react"

const Events = () => (
    <svg
        width="54"
        height="60"
        viewBox="0 0 54 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M51 24.6666H3M37.6667 3.33325V13.9999M16.3333 3.33325V13.9999M19 40.6666L24.3333 45.9999L36.3333 33.9999M15.8 56.6666H38.2C42.6804 56.6666 44.9206 56.6666 46.6319 55.7946C48.1372 55.0277 49.3611 53.8038 50.1281 52.2985C51 50.5872 51 48.347 51 43.8666V21.4666C51 16.9862 51 14.746 50.1281 13.0347C49.3611 11.5294 48.1372 10.3055 46.6319 9.53853C44.9206 8.66659 42.6804 8.66659 38.2 8.66659H15.8C11.3196 8.66659 9.07937 8.66659 7.36808 9.53853C5.86278 10.3055 4.63893 11.5294 3.87195 13.0347C3 14.746 3 16.9862 3 21.4666V43.8666C3 48.347 3 50.5872 3.87195 52.2985C4.63893 53.8038 5.86278 55.0277 7.36808 55.7946C9.07937 56.6666 11.3196 56.6666 15.8 56.6666Z"
            stroke="#FAFAFA"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default Events
