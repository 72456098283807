import React from "react"

const Messages = () => (
    <svg
        width="54"
        height="55"
        viewBox="0 0 54 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.6667 17.6667H27M13.6667 27H35M20.8233 43H38.2C42.6804 43 44.9206 43 46.6319 42.128C48.1372 41.3611 49.3611 40.1372 50.1281 38.6319C51 36.9206 51 34.6804 51 30.2V15.8C51 11.3196 51 9.07937 50.1281 7.36808C49.3611 5.86278 48.1372 4.63893 46.6319 3.87195C44.9206 3 42.6804 3 38.2 3H15.8C11.3196 3 9.07937 3 7.36808 3.87195C5.86278 4.63893 4.63893 5.86278 3.87195 7.36808C3 9.07937 3 11.3196 3 15.8V49.228C3 50.6489 3 51.3594 3.29128 51.7243C3.54459 52.0417 3.92872 52.2263 4.33477 52.2258C4.80167 52.2253 5.35645 51.7815 6.46602 50.8939L12.8272 45.8049C14.1267 44.7653 14.7765 44.2455 15.5 43.8759C16.1419 43.5479 16.8251 43.3083 17.5312 43.1633C18.3271 43 19.1592 43 20.8233 43Z"
            stroke="#FAFAFA"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default Messages
