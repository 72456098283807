import React from "react"
import { getCurrentOrganization } from "lib/auth"

const OrgLogo = () => {
    const { id } = getCurrentOrganization()
    const { logoUrl } = id
    return (
        <img
            src={logoUrl}
            style={{
                borderRadius: `6.25rem`,
                width: "6.25rem",
                height: "6.25rem"
            }}
        ></img>
    )
}

export default OrgLogo
