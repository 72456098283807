import React from "react"
import OrgLogo from "common/OrgLogo"
import { getCurrentOrganizationId } from "lib/auth"
import styles from "../../components/Settings/Settings.module.css"

const OrganizationInfo = () => {
    const { name } = getCurrentOrganizationId()
    return (
        <div className={styles.orgInfoContainer}>
            <OrgLogo />
            <span className={styles.orgName}>{name}</span>
        </div>
    )
}

export default OrganizationInfo
