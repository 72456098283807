import React from "react"

const Settings = () => (
    <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M25.0538 51.6565L26.6123 55.1616C27.0756 56.205 27.8317 57.0916 28.7889 57.7138C29.7461 58.3359 30.8633 58.667 32.0049 58.6668C33.1465 58.667 34.2637 58.3359 35.2209 57.7138C36.1781 57.0916 36.9342 56.205 37.3975 55.1616L38.956 51.6565C39.5108 50.4127 40.444 49.3758 41.6227 48.6935C42.8088 48.0093 44.1809 47.7179 45.5427 47.8609L49.356 48.2668C50.4911 48.3869 51.6367 48.1751 52.6538 47.657C53.671 47.139 54.516 46.337 55.0864 45.3483C55.6575 44.3602 55.9296 43.2276 55.8697 42.0879C55.8097 40.9481 55.4203 39.8503 54.7486 38.9276L52.4908 35.8254C51.6869 34.7125 51.2573 33.373 51.2641 32.0002C51.2639 30.6311 51.6975 29.2971 52.5027 28.1898L54.7604 25.0876C55.4321 24.1648 55.8215 23.067 55.8815 21.9273C55.9415 20.7876 55.6694 19.6549 55.0982 18.6668C54.5278 17.6781 53.6828 16.8761 52.6657 16.3581C51.6485 15.8401 50.503 15.6282 49.3678 15.7483L45.5545 16.1542C44.1927 16.2973 42.8206 16.0058 41.6345 15.3216C40.4535 14.6355 39.52 13.5931 38.9678 12.3439L37.3975 8.83868C36.9342 7.79529 36.1781 6.90874 35.2209 6.28656C34.2637 5.66438 33.1465 5.33331 32.0049 5.3335C30.8633 5.33331 29.7461 5.66438 28.7889 6.28656C27.8317 6.90874 27.0756 7.79529 26.6123 8.83868L25.0538 12.3439C24.5016 13.5931 23.5681 14.6355 22.3871 15.3216C21.201 16.0058 19.8289 16.2973 18.4671 16.1542L14.6419 15.7483C13.5068 15.6282 12.3612 15.8401 11.3441 16.3581C10.327 16.8761 9.48196 17.6781 8.91155 18.6668C8.3404 19.6549 8.0683 20.7876 8.12826 21.9273C8.18822 23.067 8.57766 24.1648 9.24933 25.0876L11.5071 28.1898C12.3123 29.2971 12.7459 30.6311 12.7456 32.0002C12.7459 33.3693 12.3123 34.7032 11.5071 35.8105L9.24933 38.9128C8.57766 39.8355 8.18822 40.9333 8.12826 42.073C8.0683 43.2128 8.3404 44.3454 8.91155 45.3335C9.48252 46.3217 10.3276 47.1233 11.3446 47.6412C12.3616 48.1592 13.5069 48.3713 14.6419 48.252L18.4553 47.8461C19.817 47.7031 21.1892 47.9945 22.3753 48.6787C23.5607 49.3629 24.4985 50.4055 25.0538 51.6565Z"
            stroke="white"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M32.0001 40.0002C36.4184 40.0002 40.0001 36.4184 40.0001 32.0002C40.0001 27.5819 36.4184 24.0002 32.0001 24.0002C27.5818 24.0002 24.0001 27.5819 24.0001 32.0002C24.0001 36.4184 27.5818 40.0002 32.0001 40.0002Z"
            stroke="white"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default Settings
