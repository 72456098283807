import React from "react"

const VideoMessages = () => (
    <svg
        width="60"
        height="59"
        viewBox="0 0 60 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M30 56.1666C44.7276 56.1666 56.6667 44.2275 56.6667 29.4999C56.6667 14.7723 44.7276 2.83325 30 2.83325C15.2724 2.83325 3.33334 14.7723 3.33334 29.4999C3.33334 44.2275 15.2724 56.1666 30 56.1666Z"
            stroke="#FAFAFA"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M23.3333 21.4075C23.3333 20.1347 23.3333 19.4983 23.5993 19.1431C23.8311 18.8335 24.1859 18.6397 24.5717 18.6122C25.0144 18.5806 25.5497 18.9247 26.6203 19.613L39.2085 27.7054C40.1376 28.3026 40.6021 28.6013 40.7625 28.981C40.9027 29.3127 40.9027 29.6871 40.7625 30.0189C40.6021 30.3986 40.1376 30.6972 39.2085 31.2944L26.6203 39.3869C25.5497 40.0751 25.0144 40.4193 24.5717 40.3876C24.1859 40.3601 23.8311 40.1664 23.5993 39.8568C23.3333 39.5015 23.3333 38.8651 23.3333 37.5924V21.4075Z"
            stroke="#FAFAFA"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default VideoMessages
