import moment from "moment-timezone"
import Router from "next/router"
import { getCurrentOrganizationId } from "./auth"

export const formatDuration = (startedAt, endedAt) => {
    const { timezone } = getCurrentOrganizationId()
    const startMoment = moment(startedAt).tz(timezone)
    const endMoment = moment(endedAt).tz(timezone)

    const today = moment().tz(timezone).startOf("day")
    const tomorrow = today.clone().add(1, "day")

    let dayLabel = ""
    if (startMoment.isSame(today, "day")) {
        dayLabel = "Today"
    } else if (startMoment.isSame(tomorrow, "day")) {
        dayLabel = "Tomorrow"
    }

    return `${dayLabel} ${startMoment.format("h:mmA")} - ${endMoment.format("h:mmA")}`.trim()
}

export const formatPublishedOn = (publishedOn) =>
    `${moment(publishedOn).format("MMM D, Y")}`

export const truncateString = (str, limit) => {
    if (str.length <= limit) {
        return str
    }

    return str.substring(0, limit - 10) + "..."
}

export const truncateLines = ({ lines, maxWidth = "29ch" }) => ({
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth,
    WebkitLineClamp: lines
})

export const truncateTwoLines = (lines) => ({
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: lines,
    WebkitBoxOrient: "vertical"
})

export const handleKeyRouteChange = (event, routeUp, routeDown) => {
    if (event.key === "ArrowUp") {
        Router.push(Router.asPath.replace(/\/[^/]*$/, routeUp))
    } else if (event.key === "ArrowDown") {
        Router.push(Router.asPath.replace(/\/[^/]*$/, routeDown))
    }
}

export const isTablet = () => {
    return (
        /iPad/i.test(navigator.userAgent) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    )
}
