import React, { useState, useEffect, useRef } from "react"
import { useRouter } from "next/router"
import styled from "styled-components"
import MessagesIcon from "common/icons/Messages"
import MenusIcon from "common/icons/Menus"
import EventsIcon from "common/icons/Events"
import VideoMessagesIcon from "common/icons/VideoMessages"
import SettingsIcon from "common/icons/Settings"
import { getCurrentOrganizationId } from "lib/auth"
import OrganizationInfo from "common/OrganizationInfo"
import { isTablet } from "lib/utils"

const MENUS = [
    {
        title: "Messages",
        icon: MessagesIcon,
        slug: "messages"
    },
    {
        title: "Menu",
        icon: MenusIcon,
        slug: "menu"
    },
    {
        title: "Events",
        icon: EventsIcon,
        slug: "events"
    },
    {
        title: "Video Messages",
        icon: VideoMessagesIcon,
        slug: "video-messages"
    }
]

const BackgroundImage = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-image: url("/static/images/visplus-background.png");
    background-position: 30%;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
    }
`

const StyledPage = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: black;

    &:focus {
        outline: none;
    }

    padding: 2rem;
    overflow-y: hidden;

    ${({ tablet }) =>
        tablet &&
        `
        height: 95vh;
    `}
`

const MenuContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 2rem;
    background-color: black;
    width: 100%;
    height: 100%;
`

const Card = styled.div`
    border-radius: 0.6rem;
    height: 29.3125rem;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    cursor: pointer;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    text-align: center;
    gap: 1rem;

    width: 100%;
    color: #fafafa;
    background-color: #27272a;

    &:focus {
        outline: 2px;
    }

    svg {
        height: 3rem;

        path {
            stroke: #fafafa;
        }
    }

    ${({ animate }) =>
        animate &&
        `
        transition: height 0.3s ease-in-out;
    `}

    ${({ selected }) =>
        selected &&
        `
        color: #134E4A;
        background-color: #2DD4BF;
        height: 40.25rem;
        width: 100%;

        svg {
            path {
                stroke: #134E4A;
            }
        }
    `}

    ${({ inactive }) =>
        inactive &&
        `
        color: #2e2e2e;
        background-color: #131313;

        svg {
            path {
                stroke: #2e2e2e;
            }
        }
    `}

    ${({ tablet }) =>
        tablet &&
        `
        background-color: rgba(45, 212, 191, 1);
        height: 295px;
        color: #000000;
        svg {
            path {
                stroke: #000000;
            }
        }
    `}

    ${({ tablet, elementTouched }) =>
        tablet &&
        elementTouched &&
        `
        background-color: rgba(153, 246, 228, 1);
        
    `}
`

const RoundedButton = styled.button`
    cursor: pointer;
    z-index: 1;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2rem;
    right: 2rem;
    padding: 1.125rem;
    border-radius: 50%;
    background-color: #27272a;

    svg {
        height: 3.9rem;
        width: auto;
    }

    ${({ selected }) =>
        selected &&
        `
        svg {
            path {
                stroke: #2DD4BF;
            }
        }
    `}
`

const OrgLogoAndName = styled.div`
    position: absolute;
    top: 2rem;
    left: 2rem;
    z-index: 1;
`

const getNextActiveIndex = (current) => {
    let next = current + 1
    if (next >= MENUS.length) {
        next = 0
    }
    if (!MENUS[next].slug) {
        return getNextActiveIndex(next)
    }
    return next
}

const getPreviousActiveIndex = (current) => {
    let previous = current - 1
    if (previous < 0) {
        previous = MENUS.length - 1
    }
    if (!MENUS[previous].slug) {
        return getPreviousActiveIndex(previous)
    }
    return previous
}

function registerSelectedMenu(slug) {
    window.sessionStorage.setItem("selectedMenu", slug)
}

function getSelectedMenu() {
    return window.sessionStorage.getItem("selectedMenu")
}

export default function MainMenu() {
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [elementTouched, setElementTouched] = useState(false)
    const [animate, setAnimate] = useState(false)
    const router = useRouter()
    const divRef = useRef(null)
    const { slug } = getCurrentOrganizationId()
    const tablet = isTablet()
    function syncSelectedMenu() {
        const selectedMenu = getSelectedMenu()
        const menuIndex = MENUS.findIndex((menu) => menu.slug === selectedMenu)
        if (menuIndex > -1) {
            setAnimate(false)
            setSelectedIndex(menuIndex)
        }
    }

    useEffect(() => {
        if (divRef.current) {
            divRef.current.focus()
        }
        syncSelectedMenu()
    }, [])

    const handleKeyDown = (event) => {
        if (event.keyCode === 38) {
            setAnimate(true)
            setSelectedIndex(999)
        } else if (event.keyCode === 40) {
            setAnimate(true)
            const selectedMenu = getSelectedMenu()
            const menuIndex = MENUS.findIndex(
                (menu) => menu.slug === selectedMenu
            )
            setSelectedIndex(menuIndex)
        } else if (event.keyCode === 39) {
            setAnimate(true)
            const index = getNextActiveIndex(selectedIndex)
            setSelectedIndex(index)
            registerSelectedMenu(MENUS[index].slug)
        } else if (event.keyCode === 37) {
            setAnimate(true)
            const index = getPreviousActiveIndex(selectedIndex)
            setSelectedIndex(index)
            registerSelectedMenu(MENUS[index].slug)
        } else if (event.keyCode === 13) {
            if (selectedIndex === 999) {
                router.push(`${slug}/settings`)
            } else {
                enterMenu(MENUS[selectedIndex])
            }
        }
    }

    const enterMenu = (menu) => {
        if (menu.slug) {
            const path = `/${slug}/content?contentType=${menu.slug}`
            // registerSelectedMenu(menu.slug)
            router.push(path)
        }
    }

    const handleTouchStart = (index) => {
        setElementTouched(index)
    }

    const handleTouchEnd = (menu) => {
        setElementTouched(-1)
        enterMenu(menu)
    }

    return (
        <>
            {tablet && <BackgroundImage />}
            <StyledPage
                onKeyDown={handleKeyDown}
                tabIndex="0"
                innerRef={divRef}
                tablet={tablet}
            >
                <OrgLogoAndName>
                    <OrganizationInfo />
                </OrgLogoAndName>

                <RoundedButton
                    selected={selectedIndex === 999}
                    onClick={() => {
                        router.push(`${slug}/settings`)
                    }}
                    onMouseEnter={() => {
                        setSelectedIndex(999)
                    }}
                    onMouseLeave={() => {
                        setSelectedIndex(null)
                    }}
                >
                    <SettingsIcon />
                </RoundedButton>

                <MenuContainer tablet={tablet}>
                    {MENUS.map((menu, index) => (
                        <Card
                            tablet={tablet}
                            elementTouched={elementTouched === index}
                            key={`menu-card-${index}`}
                            animate={animate}
                            selected={selectedIndex === index}
                            inactive={menu.slug === null}
                            onClick={() => enterMenu(menu)}
                            onMouseEnter={() => {
                                setAnimate(true)
                                setSelectedIndex(index)
                            }}
                            onMouseLeave={() => {
                                setSelectedIndex(null)
                            }}
                            onTouchStart={() => handleTouchStart(index)}
                            onTouchEnd={() => handleTouchEnd(menu)}
                        >
                            {menu.icon && <menu.icon />}
                            {menu.title}
                        </Card>
                    ))}
                </MenuContainer>
            </StyledPage>
        </>
    )
}
