import MainMenu from "components/MainMenu"
import React, { useEffect, useState } from "react"
import { useSubdomain } from "lib/Subdomain/SubdomainProvider"
import { useRouter } from "next/navigation"
import { getCurrentOrganizationId, logout } from "lib/auth"
import { useFeatureFlags } from "lib/hooks/useFeatureFlags"
import { VP_P_ENABLE_LOGIN } from "lib/FeatureFlags/Constants"

const Index = () => {
    const { getFeatureFlag } = useFeatureFlags()
    const loginEnabledOrgs = getFeatureFlag(VP_P_ENABLE_LOGIN)
    const [enabledOrg, setEnabledOrg] = useState(false)
    const subDomain = useSubdomain()
    const router = useRouter()
    const { slug, id } = getCurrentOrganizationId()

    useEffect(() => {
        if (subDomain !== "tv") {
            if (loginEnabledOrgs !== "") {
                if (loginEnabledOrgs.includes(id)) {
                    setEnabledOrg(true)
                } else {
                    logout(true)
                }
            }
        }
    }, [loginEnabledOrgs])

    useEffect(() => {
        if (subDomain === "tv") {
            router.push(`/${slug}/settings`)
        }
    }, [])

    if (enabledOrg) {
        return <MainMenu />
    }

    return null
}

export default Index
